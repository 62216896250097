import React, { createContext, useContext, useEffect, useMemo } from 'react'

const DeviceContext = createContext({
  isMobile: false
});

const Provider = DeviceContext.Provider;

export const useDevice = () => useContext(DeviceContext);

export const BREAKPOINT = 768;

export const DeviceProvider = ({children}) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const screen = useMemo(() => {
    if (width < BREAKPOINT) return 'mobile';
    return 'desktop';
  }, [width]);

  const isMobile = useMemo(() => screen === 'mobile', [screen]);

  return (
    <Provider value={{
      isMobile
    }}>
      {children}
    </Provider>
  )
};
