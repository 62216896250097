import './App.css';
import HomePageMob from './Layouts/HomePageMob';
import { useDevice } from './hooks/DeviceContext';
import HomePageDesk from './Layouts/HomePageDesk';
import { analytics } from './firebase';

function App() {
  const { isMobile } = useDevice();

  return (
    <div className='App'>
      {isMobile ?
        <HomePageMob />
        :
        <HomePageDesk />
      }
    </div>
  );
}

export default App;
