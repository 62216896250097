import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAoKU5NDMc_-5hXpR4YPaDSjrTcdSW_qsY",
  authDomain: "website-41ece.firebaseapp.com",
  projectId: "website-41ece",
  storageBucket: "website-41ece.appspot.com",
  messagingSenderId: "411565564168",
  appId: "1:411565564168:web:a0eca7e93a1dadcffb0cbd",
  measurementId: "G-T4YD9BX1NJ"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);