import styled from 'styled-components';

import React from 'react'
import Header from '../Components/Header';
import Main from '../Components/Main';
import Contacts from '../Components/Contacts';

export default function HomePageMob() {
  return (
    <Wrapper>
      <Header />
      <Main />
      <Contacts />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 3.75rem;
  padding-bottom: 0.625rem;
`;
