import React from 'react'

import styled from 'styled-components';

import Title from '../Elements/Title';
import Subtitle from '../Elements/Subtitle';
import Bio from '../Elements/Bio';
import Divider from '../Elements/Divider';
import { useDevice } from '../hooks/DeviceContext';

export default function Header() {
  const { isMobile } = useDevice();
  return (
    <Wrapper>
      <Title
        style={ isMobile ? {
          padding: '0 var(--padding-side-mob)'
        } : {}}
      >Yulia Svischeva</Title>
      <Subtitle>
      graphic and digital designer in Berlin
      </Subtitle>
      <Bio>
        I create <em>visual identities</em> and <em>websites</em>, work with printed and digital materials such as <em>logos, banners, books, posters, landing pages,</em> and <em>social media</em>.
      </Bio>
      {isMobile && <Divider />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


