import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { BREAKPOINT } from '../hooks/DeviceContext';
import Slider from './Slides/Slider';

export default function Project({
  title,
  titleColor,
  subtitle,
  description,
  images,
  link,
  linkText
}) {
  const wrapperRef = useRef(null);
  const firstImageRef = useRef(null);

  const handleResize = useCallback(() => {
    const wrapper = wrapperRef.current;
    if (wrapper === null) return;
    const firstImage = firstImageRef.current;
    if (firstImage === null) return;
    const wrapperWidth = wrapper.offsetWidth;
    const { naturalWidth, naturalHeight } = firstImage
    const newHeight = wrapperWidth / naturalWidth * naturalHeight;
    wrapper.style.height = `${newHeight}px`;
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper === null) return;
    const firstImage = wrapper.querySelector('.slide-image');
    if (firstImage === null) return;
    firstImage.onload = () => {
      firstImageRef.current = firstImage;
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [images, handleResize]);

  return (
    <Wrapper>
      <Top $titleColor={titleColor}>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
      </Top>
      <Slideshow ref={wrapperRef}>
        <Slider images={images} />
      </Slideshow>
      <Description>
        <p>{description}</p>
        <a href={link} target='_blank' rel="noreferrer">
          {linkText || 'See more on Behance'}
        </a>
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1.875rem var(--padding-side-mob);

  @media (min-width: ${BREAKPOINT}px) {
    margin: 2rem 0;
    padding: 0;

    display: grid;
    grid-template-areas: 
      "top slideshow"
      "description slideshow";
    grid-template-columns: 3fr 4fr;
    gap: 2.25rem;
  }
`;

const Top = styled.div`
  margin-bottom: 1.25rem;

  h2 {
    border-radius: 3.5rem;
    width: fit-content;
    padding: 0.5rem 1.25rem;

    background-color: ${props => props.$titleColor};

    font-size: var(--font-size-l-mob);
    font-family: var(--main-font);
    font-weight: 700;
    font-style: italic;
    line-height: 1.313rem;
    color: var(--bubble-text-color);
    text-align: center;
  }

  h3 {
    margin: 0.375rem 0 0 0.375rem;

    font-size: var(--font-size-m-mob);
    font-family: var(--main-font);
    font-weight: 400;
    font-style: italic;
    line-height: 1.25rem;
    color: var(--blue);
  
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin: 0;
    grid-area: top;

    h2 {
      padding: 0.625rem 1.75rem;
      
      font-size: var(--font-size-m-desk);
    }

    h3 {
      font-size: var(--font-size-s-desk);
      line-height: 1.375rem;
    }
  }
`;

const Slideshow = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: ${BREAKPOINT}px) {
    grid-area: slideshow;
    width: 25.813rem;
  }
`;

const Description = styled.div`
  margin-top: 1.25rem;

  p {
    margin-bottom: 0.5rem;

    font-size: var(--font-size-s-mob);
    font-family: var(--secondary-font);
    font-weight: 100;
    line-height: 0.938rem;
    color: var(--blue);
  }

  a {
    font-size: var(--font-size-s-mob);
    font-family: var(--main-font);
    font-weight: 700;
    line-height: 0.875rem;
    color: var(--green);
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin-top: auto;
    grid-area: description;
  }
`;
