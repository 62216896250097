import React from 'react'

import styled from 'styled-components';
import { BREAKPOINT } from '../hooks/DeviceContext';

import arrow from '../assets/header-arrow.svg';

export default function Subtitle({style, children}) {
  return (
    <Wrapper style={style}>
      <img src={arrow} alt='' />
      <span>{children}</span>
      <img src={arrow} alt='' />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 2.5rem;
  margin-right: var(--padding-side-mob);
  margin-left: 0.25rem;
  padding: 0.5rem var(--padding-side-mob);
  padding-right: 0;

  color: var(--blue);

  font-size: var(--font-size-l-mob);
  font-family: var(--secondary-font);
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  line-height: 1.25rem;

  img {
    height: 1.188rem;
    transform: rotate(-90deg);
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin-right: 0;
    width: 26.25rem;
    padding: 0.5rem 0 0.875rem 0;
    
    font-size: 1.188rem;
    line-height: 1.5rem;
  }
`;