import React from 'react'
import styled from 'styled-components';

export default function Credits() {
  return (
    <Wrapper>Coded by <a href='https://t.me/levabubu' target='_blank' rel="noreferrer">Lev Bulanov</a>.
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: var(--font-size-s-mob);
  font-family: var(--secondary-font);
  font-weight: 100;
  line-height: 0.938rem;
  color: var(--blue);

  a {
    color: var(--green);
  }
`;