import React from 'react'

import styled from 'styled-components';

export default function Divider({style}) {
  return (
    <DIVIDER style={style} />
  )
}

const DIVIDER = styled.div`
  margin: 0 var(--padding-side-mob);
  border-top: 2px solid var(--blue);
`;