import React from 'react'
import styled from 'styled-components';

import { useSwiper } from 'swiper/react';
import { BREAKPOINT, useDevice } from '../../hooks/DeviceContext';

import arrowMob from '../../assets/arrow-mob.svg';
import arrowDesk from '../../assets/arrow-desk.svg';

export default function NavButton({direction='prev'}) {
  const { isMobile } = useDevice();
  const swiper = useSwiper();

  const handleClick = () => {
    if (direction === 'prev') swiper.slidePrev();
    else swiper.slideNext();
  }

  return (
    <Arrow
      src={isMobile ? arrowMob : arrowDesk}
      alt={direction === 'prev' ? 'left arrow' : 'right arrow'}
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleClick();
      }}
      fetchpriority='high'
      direction={direction}
    />
  )
}

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  ${props => props.direction === 'prev' ? 'left: 0.83rem;' : 'right: 0.83rem;'}
  ${props => props.direction === 'prev' ? 'transform: translateY(-50%);' : 'transform: translateY(-50%) rotate(180deg);'}
  
  height: 1.494rem;

  cursor: pointer;
  z-index: 1;

  @media (min-width: ${BREAKPOINT}px) {
    height: 1.875rem;
  }
`;