import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import 'swiper/css';

import NavButton from './NavButton';

export default function Slider({images}) {
  return (
    <Wrapper>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        speed={400}
        loop={true}
        lazy={true}
        lazyPreloadPrevNext={1}
        pagination={{
          clickable: true,
        }}
      >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={``} className='slide-image' loading='lazy' />
        </SwiperSlide>
      ))}

        <NavButton direction='prev' />
        <NavButton direction='next' />
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide .slide-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
`;