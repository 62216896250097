import React from 'react'

import styled from 'styled-components';
import { BREAKPOINT } from '../hooks/DeviceContext';

import arrow from '../assets/header-arrow.svg';

export default function Bio({children}) {
  return (
    <BIO>
      <img src={arrow} alt='' />
      <p>{children}</p>
    </BIO>
  )
}

const BIO = styled.div`
  margin-bottom: 2.5rem;
  padding: 0 var(--padding-side-mob);

  font-size: var(--font-size-l-mob);
  font-family: var(--main-font);
  font-weight: 200;
  line-height: 1.25rem;
  color: var(--blue);

  & em {
    font-style: italic;
    font-weight: 700;
  }

  img {
    height: 1.188rem;
  }

  p {
    margin-top: 0.5rem;
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin: 0;
    padding: 0;

    width: 25rem;
    font-size: var(--font-size-l-desk);
    line-height: 1.563rem;
  }
`;
